
import { WAREHOUSUE } from "@/utils/constance";
import store from '@/store/store';
import ResourceApi from "@/api/resourceApi";

export function setOptionBusinessYear(){
    let years = [{
        label: '選択する',
        value: ''
    }];
    let data = [0,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
    data.map(i => {
        let y = {
            label: i,
            value: i
        }
        years.push(y)
    })
    return years;
}

export function setOptionBusinessMonth(){
    let month = [{
        label: '選択する',
        value: ''
    }];
    let data = [1,2,3,4,5,6,7,8,9,10,11,12]
    data.map(i => {
        let y = {
            label: i,
            value: i
        }
        month.push(y)
    })
    return  month;
}

export function getCategories(categories){
    let categoriesObj = {}
    categories.map((i, key) => {

        switch (key){
            case 0:
                categoriesObj.major_category = i.value
                break;
            case 1:
                categoriesObj.middle_category = i.value
                break;
            default:
                categoriesObj.minor_category = i.value
        }

    })
    return categoriesObj

}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cardboardFieldsLabel(wareHouseLabel, otherLabel) {
    return store.getters.user.shop_id === WAREHOUSUE ? wareHouseLabel : otherLabel
}

export function setOptionColors() {
    const apiColors = new ResourceApi('colors');
    const options = [{label: '選択する', value: ''}];

    apiColors.all().then(response => {
        response.data.data.map(color =>{
            options.push({
                label: color.color_code + " : " + color.name,
                value: color.id
            })
        });
    });

    return options;
}
export function setOptionWholeHour(){
    let hours = [{
        label: '時',
        value: ''
    }];
    let data = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
    data.map(i => {
        let h = {
            label: i,
            value: i
        }
        hours.push(h)
    })
    return hours;
}

export function setOptionByThirty(){
    let minutes = [{
        label: '分',
        value: ''
    }];
    let data = ['00','30']
    data.map(i => {
        let m = {
            label: i,
            value: i
        }
        minutes.push(m)
    })
    return minutes;
}