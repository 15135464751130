<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <strong>{{ title }}</strong>
        </CCardHeader>
        <CCardBody>
          <vue-confirm-dialog></vue-confirm-dialog>

          <ModalComponent
            title="QRコードスキャン"
            color="warning"
            :footer="false"
            :show.sync="openScanQR"
          >
            <CRow class="text-center">
              <div class="w-100">
                <div>
                  <QrcodeStream
                    :camera="camera"
                    @decode="onDecodeResult"
                    @init="onInit"
                    class="text-center"
                  >
                    <div class="p-80">
                      <div :class="result.type">
                        {{ result.message }}
                      </div>
                    </div>
                  </QrcodeStream>
                </div>
              </div>
            </CRow>
          </ModalComponent>

          <ModalComponent
            title="商品IDを入力して登録する"
            color="warning"
            :footer="false"
            :show.sync="openRegisterItem"
          >
            <div class="bg-gray-100 p-3">
              <CRow>
                <CCol md="8">
                  <CInput
                    id="id"
                    label="商品ID"
                    class="text-dark m-0"
                    horizontal
                    v-model="itemId"
                  />
                </CCol>
                <CCol md="4">
                  <CButton color="success" @click="handleRegisterItem">
                    選択
                  </CButton>
                </CCol>
              </CRow>
            </div>
          </ModalComponent>

          <ModalComponent
            title="ハンディでQRを読み取る"
            color="warning"
            :footer="false"
            :show.sync="openHandiQR">
            <div class="bg-gray-100 p-3">
              <CRow>
                <CCol md="12">
                  <div class="handy_status">
                    <div v-if="handyProcessing"  class="badge bg-secondary">処理中</div>
                    <div v-if="!handyProcessing" class="badge bg-success">読取可能</div>
                  </div>
                  <div v-if="handyText" class="handy_message" >{{ handyText }}</div>
                  <div v-if="result" class="handy_message">{{ handyResult }}</div>
                </CCol>
              </CRow>
            </div>
          </ModalComponent>
          <div class="text-sm-right mb-2">
            <CButton color="primary" class="mr-2" @click="openRegisterItem = true">
              商品IDを入力して登録する
            </CButton>
            <CButton color="primary" class="mr-2" @click="openScanQR = true">
              QRコードをスキャンして登録する
            </CButton>
            <CButton color="primary" @click="openHandiQR = true">
              ハンディでQRを読み取る
            </CButton>
          </div>
          <div>
            <slot name="dataTable"></slot>
          </div>
          <CRow class="text-center">
            <CCol md="6">
              <CButton class="h-50" color="success" @click="confirmSubmitForm">
                上記内容で登録する
              </CButton>
            </CCol>
            <CCol md="6">
              <CButton class="mr-2 h-50" color="primary" @click="goBack">
                キャンセル
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ModalComponent from "@/components/views/ModalComponent"
import { QrcodeStream } from "vue-qrcode-reader"

export default {
  name: "AddItemComponent",
  components: { ModalComponent, QrcodeStream },
  props: {
    title: String,
    confirmSubmitForm: Function,
    goBack: Function,
    onDecode: Function,
    onRegisterItem: Function,
  },

  data() {
    return {
      openScanQR: false,
      openRegisterItem: false,
      openHandiQR: false,
      itemId: "",
      camera: "auto",
      handyItemId : "",
      handyProcessing : false,
      handyText: "",
      handyResult: "",
      result: {},
    }
  },
  
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },

  methods: {
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        this.result.type = "error"
        if (error.name === "NotAllowedError") {
          this.result.message =
            "ERROR: you need to grant camera access permisson"
        } else if (error.name === "NotFoundError") {
          this.result.message = "ERROR: no camera on this device"
        } else if (error.name === "NotSupportedError") {
          this.result.message =
            "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === "NotReadableError") {
          this.result.message = "ERROR: is the camera already in use?"
        } else if (error.name === "OverconstrainedError") {
          this.result.message = "ERROR: installed cameras are not suitable"
        } else if (error.name === "StreamApiNotSupportedError") {
          this.result.message =
            "ERROR: Stream API is not supported in this browser"
        }
      }
    },

    handleRegisterItem() {
      this.onRegisterItem(this.itemId)
      this.openRegisterItem = false
      this.itemId = ""
    },

    onKeyDown(ev) {
      if(!this.openHandiQR || this.handyProcessing) {
        return;
      }
      if(ev.key == "Enter") {
        this.handyText = '商品ID：' + this.handyItemId + "をスキャン処理中"
        this.handyProcessing = true

        var self = this
        this.onDecode(this.handyItemId).then((response) => {
          self.handyItemId = ""
          self.handyText = ""
          self.handyResult = response.message
          setTimeout(() => {
            self.handyProcessing = false
          }, 500)
          setTimeout(() => {
            self.handyResult = ""
          }, 3000)
        })
      } else {
        this.handyItemId += ev.key
      }
    },

    onDecodeResult(result) {
      this.camera = "off"
      this.onDecode(result).then((response) => {
        this.result = response

        setTimeout(() => {
          this.camera = "auto"
          this.result.message = ""
        }, 1000)
      })
    },
  },
}
</script>

<style scoped>
.error {
  font-size: 40px;
  color: red;
}

.success {
  font-size: 40px;
  color: #22ff34;
  white-space: break-spaces;
  line-height: 70px;
}

.p-80 {
  padding-top: 80px;
}

.h-50 {
  height: 50px !important;
}

.handy_status {
  text-align: center;
  color: white;
  margin-bottom: 8px;
}
.handy_message {
  color: black;
  white-space: break-spaces;
}
</style>
