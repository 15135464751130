<template>
  <AddItemComponent
    title="Inspect Cardboard"
    :confirmSubmitForm="confirmChangeStatusCardboardItem"
    :goBack="() => $router.back()"
    :onDecode="onDecode"
    :onRegisterItem="onRegisterItem"
  >
    <template v-slot:dataTable>
      <CDataTable
        hover
        striped
        border
        :itemsPerPage="200"
        :items="itemMasters"
        :fields="fields"
      >
        <td slot="status_name" slot-scope="{ item }" class="text-right">
          <CSelect
            id="status"
            horizontal
            v-model="item.status"
            :options="inspectStatusOptions"
            @change="(selected) => setItemInspectStatus(selected, item.id)"
            :placeholder="'選択する'"
          />
        </td>
        <td slot="cost" slot-scope="{ item }" class="text-right">
          {{ item.cost }}
        </td>
        <td slot="price" slot-scope="{ item }" class="text-right">
          {{ item.price }}
        </td>
        <td slot="retail_price" slot-scope="{ item }" class="text-right">
          {{ item.retail_price }}
        </td>
      </CDataTable>
    </template>
  </AddItemComponent>
</template>

<script>
import Vue from "vue";
import ModalComponent from "@/components/views/ModalComponent";
import AddItemComponent from "@/components/views/AddItemComponent";
import apiCardboardOther from "@/api/cardboardApi";
import { numberWithCommas } from "@/utils/utils";
import {
  STATUS_CARDBOARD_ITEM,
  STATUS_CARDBOARD_ITEM_NAME,
} from "@/utils/constance";

export default {
  components: { ModalComponent, AddItemComponent },

  data() {
    return {
      cardboardId: this.$route.params.id,
      itemMasters: [],
      fields: [
        { key: "status_name", label: "ステータス" },
        { key: "item_id", label: "商品ID" },
        { key: "arrived_at", label: "入荷日" },
        { key: "shelf", label: "棚名" },
        { key: "item_code", label: "品番" },
        { key: "name", label: "商品名" },
        { key: "size", label: "サイズ" },
        { key: "color", label: "色" },
        { key: "cost", label: "仕入原価" },
        { key: "price", label: "販売価格" },
        { key: "retail_price", label: "プロパー上代" },
        // {key: 'delete', label: '削除'},
      ],
      loading: false,
      inspectStatusOptions: [
        {
          label: STATUS_CARDBOARD_ITEM_NAME[0],
          value: STATUS_CARDBOARD_ITEM["UN_INSPECT"],
        },
        {
          label: STATUS_CARDBOARD_ITEM_NAME[1],
          value: STATUS_CARDBOARD_ITEM["INSPECT"],
        },
      ],
    };
  },

  created() {
    this.getDataDefault();
  },

  methods: {
    async onDecode(item_id) {
      const params = {
        cardboard_id: this.cardboardId,
        item_id: item_id,
      };

      return await apiCardboardOther
        .getItemCardboardScanQR(params)
        .then((response) => {
          const data = response.data.data;
          if (!data) {
            return {
              message: response.data.message,
              type: "error",
            };
          }

          const item = {
            cardboard_id: data.cardboard_id,
            id: data.id,
            item_id: data.item_id,
          };

          if (!this.containsObject(item)) {
            return {
              message: "既に検品済みの商品です",
              type: "error",
            };
          }

          return {
            message: "アイテムは正常に検査されました!",
            type: "success",
          };
        })
        .catch(() => {
          return {
            message: "商品が在庫にありません。",
            type: "error",
          };
        });
    },

    onRegisterItem(item_id) {
      const params = {
        cardboard_id: this.cardboardId,
        item_id: item_id,
      };

      apiCardboardOther
        .getItemCardboardScanQR(params)
        .then((response) => {
          const data = response.data.data;
          if (!data) {
            Vue.$toast.error(response.data.message);
            return;
          }

          const item = {
            cardboard_id: data.cardboard_id,
            id: data.id,
            item_id: data.item_id,
          };

          if (!this.containsObject(item)) {
            Vue.$toast.error("既に検品済みの商品です");
            return;
          }

          Vue.$toast.success("アイテムは正常に検査されました");
        })
        .catch(() => {
          Vue.$toast.error("商品が在庫にありません。");
        });
    },

    confirmChangeStatusCardboardItem() {
      this.$confirm({
        title: "確認",
        message: "検品済みとして登録しても宜しいですか？",
        button: {
          no: "いいえ",
          yes: "はい",
        },
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            this.changeStatusCardboardItem();
          }
        },
      });
    },

    changeStatusCardboardItem() {
      this.loading = true;
      if (!this.itemMasters.length) {
        Vue.$toast.error("少なくとも1つの商品が必要です。");
        return;
      }
      const params = {
        cardboard_items: this.itemMasters,
      };

      apiCardboardOther
        .changeStatusCardboardItem(params)
        .then(() => {
          Vue.$toast.success("検品が完了しました。");
          setTimeout(this.$router.back(), 1000);
        })
        .catch(() => {
          Vue.$toast.error("拒否されました。");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    containsObject(obj) {
      let x = false;

      this.itemMasters.map((item) => {
        if (item.id === obj.id) {
          if (item.status == STATUS_CARDBOARD_ITEM["UN_INSPECT"]) {
            item.status = STATUS_CARDBOARD_ITEM["INSPECT"];
            item.status_name = STATUS_CARDBOARD_ITEM_NAME[1];
            x = true;
          } else {
            x = false;
          }
        }
      });
      return x;
    },

    getDataDefault() {
      if (!this.cardboardId) {
        return;
      }

      const params = {
        cardboard_id: this.cardboardId,
        screen_key: "LIST_ITEM_UNINSPECT",
      };

      apiCardboardOther
        .getItemByStatus(params)
        .then((response) => {
          // data is data in table cardboard_item
          let data = response.data;
          data.map((dt) => {
            this.handleDataDefault(dt);
          });
        })
        .catch(() => {})
        .finally();
    },

    handleDataDefault(data) {
      const item = {
        id: data.id,
        status: data.status,
        cardboard_id: data.cardboard_id,
        status_name: data.status_name,
        item_id: data.item_id,
        arrived_at: data.item.stock.instock_date,
        shelf: data.item.stock.shelf.name,
        item_code: data.item.item_master.item_code,
        name: data.item.item_master.name,
        size: data.item.item_master.size.name,
        color: data.item.item_master.color.color_code + ':' + data.item.item_master.color.name,
        cost: numberWithCommas(data.item.item_master.cost),
        price: data.item.stock.price ? numberWithCommas(data.item.stock.price) : numberWithCommas(data.item.item_master.price),
        retail_price: numberWithCommas(data.item.item_master.retail_price),
      };
      this.itemMasters.push(item);
    },

    setItemInspectStatus(selected, itemId) {
      const status = selected.target.value;
      this.itemMasters.map((item) => {
        if (item.id === itemId) {
          if (status == STATUS_CARDBOARD_ITEM["UN_INSPECT"]) {
            item.status = STATUS_CARDBOARD_ITEM["UN_INSPECT"];
            item.status_name = STATUS_CARDBOARD_ITEM_NAME[0];
          } else {
            item.status = STATUS_CARDBOARD_ITEM["INSPECT"];
            item.status_name = STATUS_CARDBOARD_ITEM_NAME[1];
          }
        }
      });
    },
  },
};
</script>
