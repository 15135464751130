import request from './request'

const cardboardApi = {
    generateQR(query) {
        return request({
            url: 'cardboard/generate-qr',
            method: 'get',
            params: query,
            responseType: 'blob'
        })
    },
    changeStatusCardboard(data) {
        return request({
            url: 'cardboard/change-status',
            method: 'post',
            data: data
        })
    },
    itemScanQR(query){
        return request({
            url: 'cardboard/item-scan-qr/' + query.cardboard_id,
            method: 'get',
            params: query
        })
    },
    changeStatusCardboardItem(data) {
        return request({
            url: 'cardboard-item/change-status',
            method: 'post',
            data: data
        })
    },
    getItemByStatus(query) {
        return request({
            url: 'cardboard/item-by-status/' + query.cardboard_id,
            method: 'get',
            params: query,
        })
    },
    getItemCardboardScanQR(query){
        return request({
            url: 'cardboard-item/item-scan-qr',
            method: 'get',
            params: query,
        })
    }
}
export default cardboardApi
