var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AddItemComponent',{attrs:{"title":"Inspect Cardboard","confirmSubmitForm":_vm.confirmChangeStatusCardboardItem,"goBack":function () { return _vm.$router.back(); },"onDecode":_vm.onDecode,"onRegisterItem":_vm.onRegisterItem},scopedSlots:_vm._u([{key:"dataTable",fn:function(){return [_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","itemsPerPage":200,"items":_vm.itemMasters,"fields":_vm.fields},scopedSlots:_vm._u([{key:"status_name",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_c('CSelect',{attrs:{"id":"status","horizontal":"","options":_vm.inspectStatusOptions,"placeholder":'選択する'},on:{"change":function (selected) { return _vm.setItemInspectStatus(selected, item.id); }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)}},{key:"cost",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.cost)+" ")])}},{key:"price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.price)+" ")])}},{key:"retail_price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.retail_price)+" ")])}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }